* {
   margin: 0;
   padding: 0;
   font-family: Regular;
   box-sizing: border-box;
}

@font-face {
   font-family: Regular;
   src: url('../public/assets/fonts/SFPRODISPLAYREGULAR.OTF');
}

@font-face {
   font-family: Bold;
   src: url('../public/assets/fonts/SFPRODISPLAYBOLD.OTF');
}

.tt-MainNav {
   background: transparent !important;
   box-shadow: none !important;
   padding: 5px 5px;
   position: absolute !important;
}

a {
   text-decoration: inherit;
   color: inherit;
}

.tt-Home {
   width: 100%;
}

.tt-Home .slick-slider {
   overflow: hidden;
}

.slick-slider .slick-next {
   right: 50px !important;
}

.slick-slider .slick-prev {
   left: 50px !important;
   z-index: +1;
}

.slick-slider .slick-prev:before {
   font-size: 50px;
}

.slick-slider .slick-next:before {
   font-size: 50px;
}

.bannerInner {
   position: relative;
}

.bannerInner img {
   min-height: 330px;
   object-fit: cover;
}

.slider-img {
   height: 545px;
}

.tt-banerInner {
   padding: 0 14px;
   text-align: center;
   position: absolute;
   top: 50%;
   transform: translateY(-40%);
   left: 0;
   width: 100%;
}

.tt-banerInner h1 {
   font-size: 40px;
   margin-bottom: 0.5rem;
   font-family: Regular;
   font-weight: 400;
   line-height: 1.2;
   color: #fff;
}

.tt-banerInner p {
   font-weight: 500;
   font-size: 16px;
   color: #fff;
}

@media (max-width: 1200px) {
   .tt-banerInner h1 {
      font-size: 32px;
   }
}

.tt-Choose p {
   color: #000;
}

.home-choose {
   padding: 60px 20px;
}

.tt-Choose.home-choose h2 {
   color: #000;
}

.tt-Choose.home-choose p {
   color: #000;
}

.ttBtn {
   border-color: #d4de4a;
   background-color: #35353a9c;
   margin-top: 40px;
   font-size: 14px;
   text-decoration: none;
   color: #fff;
   font-family: Bold;
   padding: 15px 67px;
   text-transform: uppercase;
   border-radius: 24px;
   transition: 0.3s;
   cursor: pointer;
}

.solar-wrapper {
   width: 100%;
   padding: 0;
   margin: -4px 0 0 0;
   position: relative;
}

.solar-wrapper img {
   max-height: 600px;
   object-position: center;
   object-fit: cover;
}

.solar-wrapper .solar-wrapper-img {
   position: relative;
}

.solar-wrapper .solar-wrapper-img::after {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   width: 50%;
   height: 99.5%;
   background: hsla(0, 0%, 0%, 0.8);
}

.solar-wrapper .solar-wrapper-img.inverse::after {
   left: 0;
}

@media(max-width:900px) {
   .solar-wrapper .solar-wrapper-img::after {
      background: hsla(0, 0%, 0%, 0);
   }

   .slider-img {
      height: 330px;
   }
}

.tt-solar-wrapper {
   width: 450px;
   height: auto;
   position: absolute;
   top: 25%;
   right: 20%;
}

.tt-solar-wrapper h2,
.tt-Choose h2 {
   font-size: 40px;
   margin-bottom: 0.5rem;
   font-family: Regular;
   font-weight: 400;
   line-height: 1.2;
   color: #fff;
}

.tt-solar-wrapper p,
.tt-Choose p {
   padding: 10px 0;
   font-weight: 500;
   font-size: 16px;
   color: #fff;
   line-height: 24px;
}

.tt-Choose p {
   color: #000;
}

.tt-InnerSolar-Wrapper {
   width: 450px;
   height: auto;
   position: absolute;
   top: 28%;
   left: 7%;
}

.solarBtn {
   font-weight: 500;
   border-color: #d4de4a;
   background-color: #35353a9c;
}

@media (max-width: 1600px) {
   .tt-solar-wrapper {
      right: 10%;
   }
}

@media (max-width: 1200px) {
   .tt-solar-wrapper {
      right: 5%;
   }

   .tt-solar-wrapper h2,
   .tt-Choose h2 {
      font-size: 32px;
   }
}

/* .tt-InnerSolar-Wrapper h2,
.tt-InnerSolar-Wrapper p,
.tt-InnerSolar-Wrapper .solarBtn {
  color: #000;
  border-color: #000;
} */

/* .tt-InnerSolar-Wrapper .solarBtn:hover {
  color: #FFF;
  background-color: #000;
} */

.mainPadding {
   padding: 80px 20px;
}

.tt-Choose {
   text-align: center;
}

.tt-Choose h1 {
   font-size: 40px;
   margin-bottom: 0.5rem;
   font-family: Regular;
   font-weight: 400;
   line-height: 1.2;
}

.tt-Choose p {
   font-size: 20px;
   font-weight: 400;
}

.bgGrey {
   background-color: #868686d1;
}

.tt-counter {
   display: flex;
   justify-content: center;
   align-items: center;
}

.tt_inner_counter {
   color: #fff;
   font-size: 37px;
   line-height: 1;
   font-weight: bolder;
   margin-left: 30px;
   padding-bottom: 10px;
}

.tt_flex_Counter {
   padding: 0 30px;
   width: 250px;
   text-align: center;
}

.tt_flex_Counter p {
   font-size: 18px;
   font-weight: 400;
   color: #fff;
}

.counter_inner {
   font-size: 16px;
   padding-left: 5px;
}

.iframe_vedio {
   padding-top: 50px;
}

.tt-FooterLink {
   text-decoration: none !important;
   color: #000 !important;
   font-size: 13px !important;
   font-family: Regular !important;
   font-weight: 500 !important;
}

.testimonial-slide {
   padding: 3% 0;
   width: 80% !important;
   margin: 0 auto;

}

.testimonial-slide-item {
   background: #FFF;
   margin:1% 1%;
   padding-left: 5%;
   padding-right: 5%;
   padding-top: 40%;
   padding-bottom: 50%;
   box-shadow: 0 2px 4px #999;
}

.testimonial-slide-sec .slick-list {

   padding-bottom: 2% !important;
}

.testimonial-slide-sec .slick-slide .testimonial-slide-item {
   height: 408px !important;
   margin-top: 5.6%;
}

.testimonial-slide-item img {
   margin: auto;
}

.testimonial-slide-sec .slick-slide .testimonial-slide-item {
   background-image: linear-gradient(to right, #eeeeee, #f2f2f2, #f6f6f6, #fbfbfb, #ffffff);
   opacity: 0.7;
}

.testimonial-slide-sec .slick-slide.slick-center .testimonial-slide-item {
   height: 482px !important;
   margin-top: 0;
}

.testimonial-slide-sec .slick-slide.slick-center .testimonial-slide-item {
   background-image: none;
   opacity: 1 !important;
}

.testimonial-slide-sec {
   background-color: #e7e7e7;
}

.tt-solar-inner {
   top: 10%;
   left: 12%;
}

.missionTitle {
   font-size: 20px !important;
   font-weight: 400 !important;
   line-height: 1.2;
   padding-bottom: 10px;
}

.tt-mission {
   display: flex;
   align-items: left !important;
}
.tt-mission1 {
   max-width: 100%;
   padding: 30px;
   width: 100% !important;
   display: block !important;
   justify-content: center !important;
   align-items: center !important;
   align-content:center !important ;
}

.bgLight {
   background-color: #f9f9f9;
}

.vision {
   padding: 0 50px;
}

.tt-residence {
   max-width: 1100px !important;
}

.tt-ashar {
   max-width: 1300px !important;
}

.missionCont {
   font-size: 15px;
   font-weight: 400;
   line-height: 1.5;
}

.mission_Title {
   font-size: 40px !important;
   font-weight: 500 !important;
   line-height: 1.2;
   font-family: Regular;
}

.tt-overview {
   text-align: center;
}

.tt-overview h2 {
   font-size: 40px !important;
   font-weight: 400 !important;
   font-family: Regular;
}

.tt-overview p {
   font-size: 16px !important;
   font-weight: 400 !important;
   font-family: Regular;
}

.tt_Logo_inner {
   padding: 50px 0;
   /* justify-content: center !important; */
   align-items: center !important;
}

.Logoinner {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column !important;
   padding-top: 50px !important;
}

.LogoInner {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column !important;
   padding-top: 50px !important;
   text-align: center;
   padding: 20px;
}

.LogoInner h2 {
   font-size: 24px !important;
   font-family: Regular;
   font-weight: 400;
   padding: 10px 0;
}

.tt-resig-logo {
   align-items: center;
   justify-content: center;
}

.LogoResinner {
   display: flex;
   align-items: center;
   padding-top: 50px !important;
}

.LogoResinner img {
   display: block;
   max-width: 200px;
   border-radius: 12px;
}

.LogoResinner-img,
.industrial_inner {
   cursor: pointer;
}


.industrialinner {
   max-width: 1350px !important;
}

.Logoinner p {
   font-size: 16px !important;
   font-family: Regular;
   padding: 8px 0;
   color: #363636;
}

.Logoinner h2 {
   font-size: 37px !important;
   font-weight: 500 !important;
   font-family: Regular;
   color: #363636;
}

.innerContLogo {
   font-size: 16px;
   color: #999;
   padding-left: 5px;
   font-family: Regular;
}

.tt-pt {
   padding-top: 20px !important;
}

.industrial_inner {
   padding-left: 20px;
}

.industrial_inner h5 {
   font-size: 20px;
   font-weight: bold;
   font-family: Regular;
}

.industrial_inner p {
   font-size: 15px;
   font-weight: bold;
   font-family: Regular;
}

.generationMain {
   text-align: center;
}

.generationMain h2 {
   font-size: 30px !important;
   font-family: Regular;
}

.generationBtn button {
   background-color: #000 !important;
   font-size: 13px !important;
   font-weight: 400 !important;
   text-transform: capitalize !important;
   padding: 23px 80px;
   font-family: Regular;
}

.tt-phone {
   max-width: 1350px !important;
}

.tt-phone-inner h2 {
   font-size: 40px !important;
   font-weight: 400;
   font-family: Regular;
}

.tt-phone-inner p {
   font-size: 16px !important;
   font-family: Regular;
}

.person-img {
   display: block;
   max-width: 350px;
   width: 100%;
   margin: 0 auto;
   border-radius: 50%;
}

@media (max-width: 550px) {
   .LogoResinner {
      flex-direction: column !important;
      gap: 16px;
   }

   .mission_Title {
      font-size: 30px !important;
   }

   .person-img {
      border-radius: 15px;
   }
}

@media only screen and (max-width: 900px) {
   .solar-wrapper {
      display: flex;
      flex-direction: column-reverse;
   }

   .tt-solar-wrapper h2,
   .tt-Choose h2 {
      color: #000;
      font-size: 32px;
      text-align: center;
      font-weight: 500;
      line-height: 1.2;
   }

   .tt-solar-wrapper p,
   .tt-Choose p {
      color: #000;
      font-weight: 400;
      font-family: Regular;
      text-align: center;
      font-size: 14px;
      padding-top: 0;
   }

   .tt-solar-wrapper {
      padding: 30px 20px;
      width: 100%;
      position: static;
   }

   .tt-BtnMain {
      text-align: center;
   }

   .solarBtn {
      border-color: #d4de4a;
      background-color: #35353a9c;
   }

   .solarBtn:hover {
      border-color: #d4de4a;
      background-color: #35353a9c;
   }
}

.small-Logo {
   max-width: 150px !important;
}

.choose {
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   font-family: Bold !important;
   max-width: 650px;
   margin: 0 auto;
   padding-top: 22px;
   text-align: center;
}

@media only screen and (max-width: 500px) {

   .tt-solar-wrapper h2,
   .tt-Choose h2 {
      font-size: 22px !important;
      font-family: Bold;
   }
}

@media only screen and (max-width: 768px) {
   .tt-MainNav {
      /* background: #000 !important; */
      padding: 10px 0;
      padding-top: 0px;
      /* position: static !important; */
   }

   .small-Logo {
      width: 100px !important;
   }

   .slick-slider .slick-next {
      right: 10px !important;
   }

   .slick-slider .slick-prev {
      left: 10px !important;
      z-index: +1;
   }

   .slick-slider .slick-prev:before {
      font-size: 30px;
   }

   .slick-slider .slick-next:before {
      font-size: 30px;
   }

   .ttBtn {
      padding: 11px 41px;
      margin-top: 10px;
      font-weight: 400;
      font-family: Bold;
   }

   .tt-banerInner p {
      font-size: 14px;
   }

   .tt-banerInner h1 {
      font-size: 26px;
      text-wrap: balance;
   }

   .tt-residence {
      width: 100%;
   }

   .tt-InnerSolar-Wrapper {
      left: 0;
   }

   .mainPadding {
      padding: 30px 10px;
   }

   .iframe_vedio iframe {
      width: 100%;
      height: auto;
   }

   .tt-Choose h1 {
      font-size: 32px;
   }

   .tt-counter {
      flex-direction: column !important;
   }

   .tt-Choose h2 {
      font-size: 32px;
   }

   .missionCenter {
      text-align: center;
   }

   .industrialinner {
      width: 100%;
   }

   .LogoResinner {
      flex-direction: column;
   }

   .generationBtn button {
      color: #fff;
   }

   .tt-solar-inner {
      left: 0;
   }
}

/* Contact Page */

/* Contact Info */
.contact-info-img {
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

.contact-info {
   background: #f9f9f9;
   margin-top:2px !important;
}

.contact-info .whatsapp-number,
.contact-info-address,
.contact-info-email {
   display: flex;
   align-items: center;
   gap: 6px;
   margin-bottom: 4px;
   font-size: 13.5px;
}

.contact-info .whatsapp-number img,
.contact-info-address img,
.contact-info-email img {
   width: 15px;
}

.contact-info a,
.contact-info-address,
.contact-info-number {
   text-decoration: none;
   color: #1576be;
   line-height: 24px;
   word-break: break-all;
}

.contact-info-text {
   line-height: 24px;
}

.support-img {
   max-width: 120px;
   display: block;
   width: 100%;
}

@media (max-width:680px) {
   .contact-info {
      text-align: center;
   }

   .contact-info .whatsapp-number,
   .contact-info-address,
   .contact-info-email {
      justify-content: center;
   }

   .contact-info-address {
      align-items: flex-start;
   }

   .contact-info-img {
      width: 100%;
      justify-content: center;
   }

   .contact-info-img img {
      max-width: 180px;
   }

   .support-img {
      max-width: 100px !important;
   }

   .info-divider {
      display: none;
   }

   .contact-info .whatsapp-number img,
   .contact-info-address img,
   .contact-info-email img {
      width: 15px;
   }
}

/* Contact Form */
.contactForm {
   background: #f9f9f9;
   padding: 50px 0;
   margin-top: 30px;
}

.inputField {
   padding-right: 12px;
}

.inputField label {
   display: block;
   font-size: 18px;
   color: #363636;
   font-weight: 400;
   margin-bottom: 8px;
}

.inputField input,
.inputField select {
   width: 100%;
   height: 44px;
   padding: 0 15px;
   outline: 0;
   font-size: 13px;
   color: #818181;
   line-height: 17px;
   font-weight: 400;
   border: 1px solid #e7e7e7;
   box-sizing: border-box;
}


.inputField select {
   font-size: 16px;
   color: #a7a7a7;
   cursor: pointer;
   appearance: none;
}

.selectWrapper {
   position: relative;
}

.selectWrapper img {
   width: 22px;
   position: absolute;
   top: 50%;
   right: 12px;
   transform: translateY(-50%);
   pointer-events: none;
}

.btnBlack,
.btnTrans {
   display: block;
   height: 46px;
   border: 2px solid #000;
   background-color: #000;
   color: #fff;
   border-radius: 24px;
   text-transform: uppercase;
   font-size: 14px;
   font-family: Bold;
   padding: 0 30px;
   text-decoration: none;
   transition: all 200ms ease-in-out;
   cursor: pointer;
}

.btnBlack:hover {
   background-color: transparent;
   color: #000;
}

.btnTrans {
   background-color: transparent;
   color: #000;
}

.btnTrans:hover {
   background-color: #000;
   color: #FFF;
}

/* Contact Locations */
.contactLocationBox {
   background: #f9f9f9;
   padding: 24px 18px;
   min-height: 250px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.locationTitle {
   font-size: 15px !important;
   font-family: Bold !important;
}

.locationAddress {
   font-size: 16px !important;
   font-family: Regular !important;
   line-height: 24px;
}

.contactLocationBox .btnTrans {
   max-width: 200px;
   height: 42px;
   display: flex;
   align-items: center;
   justify-content: center;
}

/* Contact Footer */
.contactFooter .btnBlack,
.contactFooter .btnTrans {
   max-width: 270px;
   width: 100%;
}

.contactFooter .inputField {
   position: relative;
}

.contactFooter .inputField input {
   border-radius: 25px;
}

.contactFooter .inputField .btnBlack {
   height: 42px;
   position: absolute;
   top: 1px;
   right: 0;
   width: auto;
}

.contactFooter .inputField .btnBlack:hover {
   background-color: black;
   color: white;
}

@media (max-width:900px) {
   .contactFooter .inputField .btnBlack {
      position: unset;
      transform: unset;
      width: 100%;
      margin-top: 16px;
   }

   .contactFooter .inputField input {
      max-width: 270px;
      display: block;
      margin: 0 auto;
   }

   .contactFooter .btnBlack,
   .contactFooter .btnTrans {
      margin: 0 auto;
   }
}

/* Mobile Nav */
.mblNavLinks {
   display: flex;
   flex-direction: column;
   gap: 20px;
   margin-left: 20px;
   margin-top: 30px;
}

.mblNavLinks a {
   text-decoration: none;
   color: white;
   font-size: 18px;
   font-family: Bold;
   cursor: pointer;
   transition: all 200ms ease-in-out;
}

.mblNavLinks a:hover {
   color: #7c7c7c;
}

/* Projects Gallery */
.my-img {
   width: 200px;
}

/* Residential Packages */
.residential-packages {
   background: #e7e7e7;
   min-height: 760px;
}

.residential-packages.mainPadding {
   padding: 60px 0;
}

.packages-tabs {
   /* max-width: 1060px; */
   width: 100%;
   margin: 65px auto;
   margin-bottom: 40px;
   border-radius: 28px;
   background-color: rgb(242, 242, 242);
   border: 1px solid rgb(216, 216, 216);
   display: flex;
   justify-content: space-around;
}

.tab-btn {
   width: 12%;
   border: none;
   outline: none;
   padding: 10px 7px;
   font-size: 23px;
   font-weight: 400;
   color: rgb(89, 89, 89);
   background: transparent;
   vertical-align: middle;
   text-align: center;
   cursor: pointer;
   position: relative;
   z-index: 1;
}

.tab-btn.active {
   color: #FFF;
}

.tab-btn.active::before {
   content: "";
   width: 170%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   background: #000;
   border-radius: 28px;
   z-index: -1;
}

/* Package Content */
.package-content {
   max-width: 600px;
}

.package-header {
   margin-bottom: 16px;
}

.package-header h3 {
   font-size: 24px;
   line-height: 1.8;
}

.package-header p {
   line-height: 1.3;
   color: #363636;
}

.package-header h4 {
   margin-top: 15px;
}

.package-content h4 {
   font-size: 16px;
   font-family: Bold;
   line-height: 1.3;
}

.info-box {
   margin-bottom: 18px;
}

.info-box h4 {
   margin-top: 4px;
}

@media (max-width:800px) {
   .tab-btn {
      width: 18%;
      padding: 10px 7px;
      font-size: 18px;

   }

   .tab-btn.active::before {
      width: 110%;
   }
}

@media (max-width:500px) {
   .packages-tabs {
      border-radius: 18px;
      margin: 40px auto;
      margin-bottom: 28px;
   }

   .package-header h3 {
      font-size: 22px;
   }

   .tab-btn {
      width: 20%;
      padding: 9px 7px;
      font-size: 14px;
   }

   .tab-btn.active::before {
      width: 100%;
      border-radius: 18px;
   }
}

/* Terms and Conditions */
.terms .mission_Title {
   text-wrap: balance;
   padding-bottom: 40px;
}

.terms p {
   margin-top: 16px;
   font-size: 18px;
   line-height: 1.5;
   color: #686868;
}

/* Privacy Policy */
.privacy .mission_Title {
   text-wrap: balance;
   padding-bottom: 40px;
}

.privacy h3 {
   font-size: 24px;
   font-weight: 500;
   margin-top: 28px;
   margin-bottom: 6px;
   line-height: 1.5;
}

.privacy p {
   font-size: 18px;
   line-height: 1.5;
   color: #686868;
}

.chat-buttons {
   position: fixed;
   bottom: 40px;
   right: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 10px;
   z-index: 9999;
}

.chat-buttons img {
   max-width: 50px;
   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.30));
}

@media (max-width:768px) {
   .chat-buttons {
      bottom: 20px;
      right: 15px;
      gap: 4px;
   }

   .chat-buttons img {
      max-width: 45px;
   }
}